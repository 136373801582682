import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';
import ContactMap from '../components/ContactMap';
import FooterInit from '../components/FooterInit';
import HeaderInit from '../components/HeaderInit';
import config from '../config';
import DevAccessValidation from '../components/DevAccessValidation';


export default function Home({ setShowSidebar, setIsIndex }) {

    const navigate = useNavigate()

    const curEnv = config.env

    const [showLoader, setShowLoader] = React.useState(true);

    //   const { enqueueSnackbar } = useSnackbar();

    const jumpToRepair = () => {
        // const userToken = Cookies.get('acc_token')
        // console.log(userToken)
        // if (!userToken) {
        //   enqueueSnackbar('Please sign in first',  {variant: 'error'})
        //   navigate('/SignIn')
        //   return;
        // }
        navigate('/Repair')
    }

    const jumpToServices = () => {
        navigate('/Services')
    }

    const dev_verify_status = localStorage.getItem('dev_verify_status');

    React.useEffect(() => {
        setShowSidebar(0)
        setIsIndex(1)
        setShowLoader(false)

        // const dev_verify_status = localStorage.getItem('dev_verify_status');
        // console.log(dev_verify_status)
        // if (curEnv != 'PROD' && dev_verify_status != 1) {
        //     setOpen(true)
        // }

        return () => {
            setIsIndex(0)
        }
    }, []);

    return (
        <>
            {curEnv !== 'PROD' && dev_verify_status != 1 ? <DevAccessValidation /> : (
                <div style={{ width: '100%' }}>
                    <HeaderInit />
                    <Loader showLoader={showLoader} />
                    <section>
                        <div className="flexslider screen-height" >

                            <div className="slides">

                                <div className="slide">

                                    <img src="assets/images/parts/carousell/technician.png" alt="Our smartphone repair technician"
                                        data-cover-image="true" />

                                    <div className="theme-back"></div>
                                    <div className="pos-center text-center col-12 text-white">
                                        <br />
                                        <div className="banner-title res-text-xxl">Book Your Repair<br></br>We Come to You!</div>

                                        <div className="banner-subtitle res-text-md">
                                            <p>
                                                Fix Your Phone On The Spot in <b>Greater Sydney Area</b>
                                                <br />
                                                High coverage of many smartphone models e.g. Samsung, iPhone, Pixel and more.
                                                <br />
                                            </p>
                                            <a href="" className="btn-lg btns-bordered-alt btn text-upper" onClick={jumpToRepair}>Book Now</a>
                                            &nbsp;
                                            or call
                                            &nbsp;
                                            <a href="tel:+61411937372" className="btn-lg btns-bordered-alt btn text-upper"><i class="fas fa-phone-alt"></i> &nbsp;  0411 93 73 72 </a>
                                            &nbsp; 
                                            to enquire
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className="flex-custom-navigation">

                                <a href="#" className="flex-prev">
                                    <i className="fas fa-angle-left" aria-hidden="true"></i>
                                </a>
                                <a href="#" className="flex-next">
                                    <i className="fas fa-angle-right" aria-hidden="true"></i>
                                </a>
                            </div>

                        </div>

                    </section>

                    <div className="clearfix muted-bg" >



                        <section className="md-stuck-top content-section" >
                            <div className="container hyped-block" >


                                <div className="row cols-md rows-md" >
                                    <div className="md-col-6">
                                        <div className="row cols-md rows-md">
                                            <div className="sm-col-6">
                                                <div className="price-block simple" data-inview-showup="showup-translate-up">
                                                    <div className="price-back"></div>

                                                    <div className="price-image">
                                                        <img className="image" src="./assets/images/icons/smartphone-screen-dark.png" alt="Smartphone Screen Repair" />
                                                    </div>

                                                    <div className="price-title">LCD Repair</div>
                                                    <div className="price-subtext">starting at...</div>
                                                    <div className="price">$119</div>
                                                    <a href="" onClick={jumpToRepair} className="btn-md btns-bordered btn text-upper">See more</a>
                                                </div>
                                            </div>
                                            <div className="sm-col-6">
                                                <div className="price-block simple" data-inview-showup="showup-translate-up">
                                                    <div className="price-back"></div>

                                                    <div className="price-image">
                                                        <img className="image" src="./assets/images/icons/battery-replacement-icon.png" alt="Smartphone Battery Replacement" />
                                                    </div>

                                                    <div className="price-title">Battery Replacement</div>
                                                    <div className="price-subtext">starting at...</div>
                                                    <div className="price">$99</div>
                                                    <a href="" className="btn-md btns-bordered btn text-upper" onClick={jumpToRepair}>See more</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="md-col-6">
                                        <div className="row cols-md rows-md">
                                            <div className="sm-col-6">
                                                <div className="price-block simple" data-inview-showup="showup-translate-up">
                                                    <div className="price-back"></div>

                                                    <div className="price-image">
                                                        <img className="image" src="./assets/images/icons/charging-service.webp" alt="Smartphone Charging Repair" />
                                                    </div>

                                                    <div className="price-title">Charging Repair</div>
                                                    <div className="price-subtext">starting at...</div>
                                                    <div className="price">$129</div>
                                                    <a href="" className="btn-md btns-bordered btn text-upper" onClick={jumpToRepair}>See more</a>
                                                </div>
                                            </div>
                                            <div className="sm-col-6">
                                                <div className="price-block simple" data-inview-showup="showup-translate-up">
                                                    <div className="price-back"></div>

                                                    <div className="price-image">
                                                        <img className="image" src="./assets/images/icons/camera-replacement-service.png" alt="Smartphone Camera Repair" />
                                                    </div>

                                                    <div className="price-title">Camera Replacement</div>
                                                    <div className="price-subtext">starting at...</div>
                                                    <div className="price">$139</div>
                                                    <a href="" className="btn-md btns-bordered btn text-upper" onClick={jumpToRepair}>See more</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </section>

                        <section className="content-section">
                            <div className="container" >

                                <div className="section-head text-center container-md">

                                    <h2 className="section-title text-upper text-lg" data-inview-showup="showup-translate-right">Why choose
                                        us</h2>

                                    <p data-inview-showup="showup-translate-left">Some Of Our Features</p>
                                </div>
                                <div className="row cols-md rows-lg text-center">
                                    <div className="md-col-6">
                                        <div className="row cols-md rows-lg">
                                            <div className="sm-col-6">
                                                <div className="feature feature-side text-left" data-inview-showup="showup-translate-up">
                                                    <div className="feature-icon"><i className="fas fa-rocket" aria-hidden="true"></i></div>
                                                    <div className="feature-title text-upper">We are fast</div>
                                                    <div className="feature-text"> Thanks to our highly skilled technicians, efficient diagnostic tools, and a well-organized repair process</div>
                                                </div>
                                            </div>
                                            <div className="sm-col-6">
                                                <div className="feature feature-side text-left" data-inview-showup="showup-translate-up">
                                                    <div className="feature-icon"><i className="fas fa-dollar-sign" aria-hidden="true"></i>
                                                    </div>
                                                    <div className="feature-title text-upper">No fix, no fee</div>
                                                    <div className="feature-text"> You only pay for our service if we successfully repair your device. If we can't fix the issue, you won't incur any charges</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="md-col-6">
                                        <div className="row cols-md rows-lg">
                                            <div className="sm-col-6">
                                                <div className="feature feature-side text-left" data-inview-showup="showup-translate-up">
                                                    <div className="feature-icon"><i className="far fa-calendar-check" aria-hidden="true"></i>
                                                    </div>
                                                    <div className="feature-title text-upper">1 Year warranty</div>
                                                    <div className="feature-text">We stand by our work. If any issues arise related to the repair within the first year, we'll fix it at no additional cost to you</div>
                                                </div>
                                            </div>
                                            <div className="sm-col-6">
                                                <div className="feature feature-side text-left" data-inview-showup="showup-translate-up">
                                                    <div className="feature-icon"><i className="fas fa-users" aria-hidden="true"></i></div>
                                                    <div className="feature-title text-upper">Expert staff</div>
                                                    <div className="feature-text">Our team comprises of expert technicians with extensive knowledge and experience to provide top-notch solutions and advice for your problem</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </section>



                    </div>
                    <section className="with-bg solid-section">

                        <div className="fix-image-wrap" data-image-src="assets/images/parts/pexels-andrea-piacquadio-3823542.jpg" data-parallax="scroll"></div>
                        <div className="theme-back inner-shadow"></div>
                        <div className="container text-center">

                            <div className="section-head text-center container-md">

                                <h2 className="section-title text-upper text-lg" data-inview-showup="showup-translate-right">Service process
                                </h2>

                                <p data-inview-showup="showup-translate-left">easy and effective way to get your device repaired</p>
                            </div>
                            <div className="service-steps text-upper" data-inview-showup="showup-scale">

                                <div className="step"><span className="step-number">1</span>damage device</div>

                                <div className="step"><span className="step-number">2</span>we come to you</div>

                                <div className="step"><span className="step-number active">3</span>fast fix</div>

                                <div className="step"><span className="step-number">4</span>quick return</div>



                            </div>

                        </div>

                    </section>
                    <section className="muted-bg solid-section">
                        <div className="container">

                            <div className="section-head text-center container-md">

                                <h2 className="section-title text-upper text-lg" data-inview-showup="showup-translate-right">Services</h2>

                                <p data-inview-showup="showup-translate-left">We offer a full range of repair services provided by an
                                    experienced and specialized team</p>
                            </div>
                            <div className="row cols-md rows-md">


                                <div className="md-col-4 sm-col-6">
                                    <div className="item" data-inview-showup="showup-translate-up">
                                        <a href="" onClick={jumpToServices} className="block-link text-center">
                                            <span className="image-wrap"><img className="image" src="assets/images/parts/cracked-screen.jpg"
                                                alt="Cracked Screen Replacement" /></span>
                                            <span className="hover">
                                                <span className="hover-show">
                                                    <span className="back"></span>
                                                    <span className="content"><i className="fas fa-search" aria-hidden="true"></i></span>
                                                </span>
                                            </span>
                                        </a>
                                        <div className="item-content">
                                            <div className="item-title text-upper">Cracked screen</div>

                                            <a href="" onClick={jumpToServices} className="btn btn-md btns-bordered pull-right text-upper">read more</a>
                                        </div>
                                    </div>
                                </div>


                                <div className="md-col-4 sm-col-6">
                                    <div className="item" data-inview-showup="showup-translate-up">
                                        <a href="" onClick={jumpToServices} className="block-link text-center">
                                            <span className="image-wrap"><img className="image" src="assets/images/parts/battery-issue.jpg"
                                                alt="Battery Issue Repair" /></span>
                                            <span className="hover">
                                                <span className="hover-show">
                                                    <span className="back"></span>
                                                    <span className="content"><i className="fas fa-search" aria-hidden="true"></i></span>
                                                </span>
                                            </span>
                                        </a>
                                        <div className="item-content">
                                            <div className="item-title text-upper">Battery Problem</div>

                                            <a href="" onClick={jumpToServices} className="btn btn-md btns-bordered pull-right text-upper">read more</a>
                                        </div>
                                    </div>
                                </div>


                                <div className="md-col-4 sm-col-6">
                                    <div className="item" data-inview-showup="showup-translate-up">
                                        <a href="" onClick={jumpToServices} className="block-link text-center">
                                            <span className="image-wrap"><img className="image" src="assets/images/parts/camera-repair.webp"
                                                alt="Camera Replacement Service" /></span>
                                            <span className="hover">
                                                <span className="hover-show">
                                                    <span className="back"></span>
                                                    <span className="content"><i className="fas fa-search" aria-hidden="true"></i></span>
                                                </span>
                                            </span>
                                        </a>
                                        <div className="item-content">
                                            <div className="item-title text-upper">Camera Damage</div>

                                            <a href="" onClick={jumpToServices} className="btn btn-md btns-bordered pull-right text-upper">read more</a>
                                        </div>
                                    </div>
                                </div>


                                <div className="md-col-4 sm-col-6">
                                    <div className="item" data-inview-showup="showup-translate-up">
                                        <a href="" onClick={jumpToServices} className="block-link text-center">
                                            <span className="image-wrap"><img className="image" src="assets/images/parts/charging-repair.png"
                                                alt="Charging Problem Repair" /></span>
                                            <span className="hover">
                                                <span className="hover-show">
                                                    <span className="back"></span>
                                                    <span className="content"><i className="fas fa-search" aria-hidden="true"></i></span>
                                                </span>
                                            </span>
                                        </a>
                                        <div className="item-content">
                                            <div className="item-title text-upper">CHARGING PROBLEM</div>

                                            <a href="" onClick={jumpToServices} className="btn btn-md btns-bordered pull-right text-upper">read more</a>
                                        </div>
                                    </div>
                                </div>


                                <div className="md-col-4 sm-col-6">
                                    <div className="item" data-inview-showup="showup-translate-up">
                                        <a href="" onClick={jumpToServices} className="block-link text-center">
                                            <span className="image-wrap"><img className="image" src="assets/images/parts/audio-repair.png"
                                                alt="Reception Issue Repair" /></span>
                                            <span className="hover">
                                                <span className="hover-show">
                                                    <span className="back"></span>
                                                    <span className="content"><i className="fas fa-search" aria-hidden="true"></i></span>
                                                </span>
                                            </span>
                                        </a>
                                        <div className="item-content">
                                            <div className="item-title text-upper">AUDIO PROBLEM</div>

                                            <a href="" onClick={jumpToServices} className="btn btn-md btns-bordered pull-right text-upper">read more</a>
                                        </div>
                                    </div>
                                </div>


                                <div className="md-col-4 sm-col-6">
                                    <div className="item" data-inview-showup="showup-translate-up">
                                        <a href="" onClick={jumpToServices} className="block-link text-center">
                                            <span className="image-wrap"><img className="image" src="assets/images/parts/backglass-repair.png"
                                                alt="Camera Issue Repair" /></span>
                                            <span className="hover">
                                                <span className="hover-show">
                                                    <span className="back"></span>
                                                    <span className="content"><i className="fas fa-search" aria-hidden="true"></i></span>
                                                </span>
                                            </span>
                                        </a>
                                        <div className="item-content">
                                            <div className="item-title text-upper">Cracked Back Glass</div>

                                            <a href="" onClick={jumpToServices} className="btn btn-md btns-bordered pull-right text-upper">read more</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="text-center shift-xl">
                                <a className="btn text-upper" href="" onClick={jumpToServices} data-inview-showup="showup-translate-up"><i
                                    className="fas fa-th-large" aria-hidden="true"></i>&nbsp;&nbsp;view all services</a>
                            </div>

                        </div>
                    </section>

                    <section className="content-section">
                        <div className="container">

                            <div className="section-head text-center container-md">

                                <h2 className="section-title text-upper text-lg" data-inview-showup="showup-translate-right">What people say
                                </h2>

                                <p data-inview-showup="showup-translate-left">Our customer reviews</p>
                            </div>
                            <div className="owl-carousel" data-inview-showup="showup-translate-up" data-owl-dots="true">
                                <div className="item">
                                    <div className="simple-testimonial text-center">
                                        <div className="tt-title">Make it look ok again</div>
                                        <div className="tt-rating"><i className="tt-star fa fa-star" aria-hidden="true"></i><i
                                            className="tt-star fa fa-star" aria-hidden="true"></i><i className="tt-star fa fa-star"
                                                aria-hidden="true"></i><i className="tt-star fa fa-star" aria-hidden="true"></i><i
                                                    className="tt-star fa fa-star" aria-hidden="true"></i></div>
                                        <div className="tt-content">
                                            <div className="tt-quote">&#8220;</div>
                                            My Iphone 13’s screen was badly cracked since I dropped it so many times but MR Xpress was able to adjust to my suitable time and replace the screen to make it look ok again. Recommended👍🏻
                                        </div>
                                        <div className="tt-icon"><img src="./assets/images/icons/mobile-sm.png" alt="Mobile repair services" /></div>
                                        <div className="pexx-tt-user-title">Amy Nguyen</div>
                                        <div className="pexx-tt-user-subtitle">Our valued customer</div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="simple-testimonial text-center">
                                        <div className="tt-title">My phone looks like new</div>
                                        <div className="tt-rating"><i className="tt-star fa fa-star" aria-hidden="true"></i><i
                                            className="tt-star fa fa-star" aria-hidden="true"></i><i className="tt-star fa fa-star"
                                                aria-hidden="true"></i><i className="tt-star fa fa-star" aria-hidden="true"></i><i
                                                    className="tt-star fa fa-star" aria-hidden="true"></i></div>
                                        <div className="tt-content">
                                            <div className="tt-quote">&#8220;</div>
                                            MR Xpress Home Service replaced my iPhone 14 Pro screen, and I'm delighted with the outcome. The service was exceptional from beginning to end. The technician was timely, professional, and courteous. The screen replacement quality is superb – my phone looks like new! The convenience of doorstep repair was unmatched. For mobile device repairs, choose MR Xpress Home Service. They truly provide five-star service!
                                        </div>
                                        <div className="tt-icon"><img src="./assets/images/icons/mobile-sm.png" alt="Smartphone repair services" /></div>
                                        <div className="pexx-tt-user-title">Hano Huang</div>
                                        <div className="pexx-tt-user-subtitle">Our valued customer</div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="simple-testimonial text-center">
                                        <div className="tt-title">Professional and courteous</div>
                                        <div className="tt-rating"><i className="tt-star fa fa-star" aria-hidden="true"></i><i
                                            className="tt-star fa fa-star" aria-hidden="true"></i><i className="tt-star fa fa-star"
                                                aria-hidden="true"></i><i className="tt-star fa fa-star" aria-hidden="true"></i><i
                                                    className="tt-star fa fa-star" aria-hidden="true"></i></div>
                                        <div className="tt-content">
                                            <div className="tt-quote">&#8220;</div>
                                            I can't recommend MR Xpress Home Service enough! They recently replaced the screen on my iPhone 13 and I couldn't be happier with the result. From start to finish, the service was exceptional. The technician arrived right on time and was incredibly professional and courteous throughout the process. The quality of the screen replacement is outstanding – my phone looks brand new again! Plus, the convenience of having the repair done at my doorstep was unbeatable. If you're in need of mobile device repairs, look no further than MR Xpress Home Service. They truly go above and beyond to deliver five-star service!
                                        </div>
                                        <div className="tt-icon"><img src="./assets/images/icons/mobile-sm.png" alt="Mobile repair services" /></div>
                                        <div className="pexx-tt-user-title">Bich Hong Cao</div>
                                        <div className="pexx-tt-user-subtitle">Our valued customer</div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="simple-testimonial text-center">
                                        <div className="tt-title">Fast service</div>
                                        <div className="tt-rating"><i className="tt-star fa fa-star" aria-hidden="true"></i><i
                                            className="tt-star fa fa-star" aria-hidden="true"></i><i className="tt-star fa fa-star"
                                                aria-hidden="true"></i><i className="tt-star fa fa-star" aria-hidden="true"></i><i
                                                    className="tt-star fa fa-star" aria-hidden="true"></i></div>
                                        <div className="tt-content">
                                            <div className="tt-quote">&#8220;</div>
                                            Great fast service!
                                        </div>
                                        <div className="tt-icon"><img src="./assets/images/icons/mobile-sm.png" alt="Smartphone repair services" /></div>
                                        <div className="pexx-tt-user-title">Glenn Barnes</div>
                                        <div className="pexx-tt-user-subtitle">Our valued customer</div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="simple-testimonial text-center">
                                        <div className="tt-title">Quick response</div>
                                        <div className="tt-rating"><i className="tt-star fa fa-star" aria-hidden="true"></i><i
                                            className="tt-star fa fa-star" aria-hidden="true"></i><i className="tt-star fa fa-star"
                                                aria-hidden="true"></i><i className="tt-star fa fa-star" aria-hidden="true"></i><i
                                                    className="tt-star fa fa-star" aria-hidden="true"></i></div>
                                        <div className="tt-content">
                                            <div className="tt-quote">&#8220;</div>
                                            Very quick response and turnaround for fixing my iPhone cracked screen at a competitive price
                                        </div>
                                        <div className="tt-icon"><img src="./assets/images/icons/mobile-sm.png" alt="Mobile repair services" /></div>
                                        <div className="pexx-tt-user-title">Amanda Ng</div>
                                        <div className="pexx-tt-user-subtitle">Our valued customer</div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="simple-testimonial text-center">
                                        <div className="tt-title">Good and knowledgeable</div>
                                        <div className="tt-rating"><i className="tt-star fa fa-star" aria-hidden="true"></i><i
                                            className="tt-star fa fa-star" aria-hidden="true"></i><i className="tt-star fa fa-star"
                                                aria-hidden="true"></i><i className="tt-star fa fa-star" aria-hidden="true"></i><i
                                                    className="tt-star fa fa-star" aria-hidden="true"></i></div>
                                        <div className="tt-content">
                                            <div className="tt-quote">&#8220;</div>
                                            Good service , never thought phone can be repaired this way. Technicians are good and knowledgeable. I would recommmend this service.
                                        </div>
                                        <div className="tt-icon"><img src="./assets/images/icons/printer-sm.png" alt="Smartphone repair services" /></div>
                                        <div className="pexx-tt-user-title">Mithil Kapre</div>
                                        <div className="pexx-tt-user-subtitle">Our valued customer</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>


                    <ContactMap />
                    <a href="#" className="scroll-top disabled"><i className="fas fa-angle-up" aria-hidden="true"></i></a>


                    <FooterInit />

                </div>
            )}
        </>

    );
}