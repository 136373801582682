import * as React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Api from '../utils/request/http';
import { useSnackbar } from 'notistack';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DirectionsIcon from '@mui/icons-material/Directions';
import Item from '@mui/material/ListItem';
import Loader from '../components/Loader';
import {formatMobileNumber} from '../utils/functions';

export default function Review({ allData, setAllData, pageDat, setPageDat }) {
  console.log(allData)
  const { enqueueSnackbar } = useSnackbar();

  const [promoInfo, setPromoInfo] = React.useState({});

  const [repairInfo, setRepairInfo] = React.useState([]);

  const [products, setProducts] = React.useState([]);

  const [checkout, setCheckout] = React.useState({});
  console.log(checkout)

  const [promoCode, setPromoCode] = React.useState('');

  const handleDiscount = () => {
    fetchCheckPromoCode(promoCode)
  }

  const handlePromoCode = (event) => {
    setPromoCode(event.target.value);
    // setAllData({ ...allData, promoCode: event.target.value });
  }

  const fetchCheckPromoCode = (promoCode={}) => {
    Api.get('/customer/checkPromoCode',{promoCode: promoCode})
      .then((data) => {
        if (data.code !== 1000) {
          enqueueSnackbar(data.msg,  {variant: 'error'})
        } else {
          
          fetchCheckout(promoCode)
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        enqueueSnackbar('Network error',  {variant: 'error'})
      });
  };

  const fetchCheckout = (promoCode='') => {
    // let cart = Object.keys(allData.cart).join(',')
    Api.post('/customer/checkoutList',{...allData, promoCode: promoCode})
      .then((data) => {
        if (data.code !== 1000) {
          enqueueSnackbar(data.msg,  {variant: 'error'})
        } else {
          setAllData({ ...allData, promoCode: promoCode });
          setRepairInfo(data.data.repairInfo);
          setProducts(data.data.productList);
          setPromoInfo(data.data.promotion);
          setCheckout(data.data);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        enqueueSnackbar('Network error',  {variant: 'error'})
      });
  };

  React.useEffect(() => {
    
    fetchCheckout(promoCode);
    // console.log('component', componentDat)
    

  }, []);

  return (
    <React.Fragment>
      <Loader showLoader={repairInfo.length === 0} />
      <Typography variant="h6" gutterBottom >
        Repair summary
      </Typography>
      <TableContainer component={Paper} sx={{ marginBottom: '16px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Phone type</TableCell>
                <TableCell>Repair type</TableCell>
                <TableCell>Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {repairInfo.map((repairItem) => (
                <TableRow key={repairItem.id}>
                  <TableCell>{repairItem.phone_name}</TableCell>
                  <TableCell>{repairItem.repair_name}</TableCell>
                  <TableCell>${repairItem.price}</TableCell>
                </TableRow>
              ))}
                
                <TableRow key='subtotal'>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>Subtotal: ${checkout.repairPrice}</TableCell>
                </TableRow>

              {checkout.repairReduced > 0 ? (
                <TableRow key='reduced'>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>Reduced: - ${checkout.repairReduced}</TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      {products.length > 0 ? (
        <>
        <Typography variant="h6" gutterBottom>
        Accessories summary
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell key='Item'>Item</TableCell>
                <TableCell key='Quantity'>Quantity</TableCell>
                <TableCell key='Price'>Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product) => (
                <TableRow key={product.id}>
                  <TableCell>
                    <img src={product.image} alt={product.name} style={{ width: '64px', height: '64px' }} />
                    <Typography variant="body1">{product.name}</Typography>
                  </TableCell>
                  <TableCell>{allData.cart[product.id]}</TableCell>
                  <TableCell>${product.price}</TableCell>
                </TableRow>
              ))}
                <TableRow key='subtotal'>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell >Subtotal: ${checkout.productPrice}</TableCell>
                </TableRow>
                {checkout.productReduced > 0 ? (
                  <TableRow key='reduced'>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell rowSpan={2}>Reduced: - ${checkout.productReduced}</TableCell>
                  </TableRow>
                ) : null}
            </TableBody>
          </Table>
        </TableContainer>
        </>
      ) : null}

      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Promotion code
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
        <TextField
          label="Code"
          id="outlined-size-small"
          size="small"
          onChange={handlePromoCode}
        />
        <IconButton color="primary" onClick={handleDiscount} sx={{ p: '10px' }} aria-label="directions">
          <DirectionsIcon />
        </IconButton>
          
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography>Discount: { checkout.finalReduced > 0 ? checkout.promotion.name : 'None'}</Typography>
          {checkout.finalReduced > 0 ? <Typography>Total discount: - ${checkout.finalReduced}</Typography> : null}
          {/* <Typography gutterBottom>Discount: None</Typography> */}
        </Grid>
        
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}></Grid>

        <Grid item xs={12} sm={6}>
          <Typography>Card processing fee: ${checkout.paymentFee}</Typography>
        </Grid>
        
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}></Grid>


        <Grid item xs={12} sm={6}>
          <Typography  >
            Total: ${checkout.finalTotal}
          </Typography>
          
        </Grid>
        
      </Grid>
      
      
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Repair schedule
          </Typography>
          <Typography gutterBottom><b>Full name: </b>{allData.firstName} {allData.lastName}</Typography>
          <Typography gutterBottom><b>Mobile: </b>{formatMobileNumber(allData.mobile)}</Typography>
          <Typography gutterBottom><b>Address: </b>{allData.address}</Typography>
          <Typography gutterBottom><b>Preferred time: </b>{allData.preferTime}</Typography>
        </Grid>
        
      </Grid>
    </React.Fragment>
  );
}
